<template>
  <div>
    <AppCard
      class="validation-page__panel"
      body-class="flex column q-pa-lg"
      header-color="bg-grey-11"
      :title="$t('label.validation')"
      bordered
    >
      <div class="form-builder__option form-builder__option_horizontal">
        <AppInput
          v-model="formPart.successMessage"
          :label="$t('label.successMessage')"
          class="app-input_textarea"
          type="textarea"
        />
        <AppColorPicker
          v-model="formPart.successMessageIconColor"
          :label="$t('label.successMessageIconColor')"
        />
        <AppColorPicker
          v-model="formPart.successMessageTextColor"
          :label="$t('label.successMessageTextColor')"
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppSelect
          v-model="formPart.positionValidationMessage"
          :options="positionOptions"
          :label="$t('label.positionValidationMessage')"
          emit-value
          map-options
        />
        <AppColorPicker
          v-model="formPart.errorMessageBackgroundColor"
          :label="$t('label.errorMessageBackgroundColor')"
        />
        <AppColorPicker
          v-model="formPart.errorMessageTextColor"
          :label="$t('label.errorMessageTextColor')"
        />
        <AppColorPicker
          v-model="formPart.invalidFieldColor"
          :label="$t('label.invalidFieldColor')"
        />
        <AppColorPicker
          v-model="formPart.invalidFieldBorderColor"
          :label="$t('label.invalidFieldBorderColor')"
        />
      </div>
    </AppCard>
    <div class="page-hint">
      {{ $t('text.validationPageHint') }}
    </div>
  </div>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppColorPicker from 'common/components/AppColorPicker';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import { builderMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'ValidationPage',
  stepName: ENUMS.STEPS.VALIDATION,
  components: {
    AppCard,
    AppSelect,
    AppInput,
    AppColorPicker,
  },
  mixins: [builderMixin],
  data() {
    return {
      positionOptions: [
        { label: this.$t('label.aboveField'), value: 'above' },
        { label: this.$t('label.belowField'), value: 'below' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.validation-page__panel {
  .form-builder__option_horizontal {
    .app-input,
    .app-select {
      grid-template-columns: 1fr 2.76fr;
    }
  }

  .color-picker {
    max-width: unset;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1.35fr !important;
    gap: 20px;

    label {
      align-items: center;
    }
  }
}
</style>
